import React from "react"
import { Col, Container, Image, Row } from "react-bootstrap"
import { box, subTitle, title, para, section } from "./styles/teams.module.css"
import yamlData from "../../content/societe/teams.yaml"

const teams = () => (
  <section className={`${section} animated fadeInUp delay-0.2s`}>
    <Container className={box}>
      <h2 className={title}>{yamlData.title}</h2>
      <Row>
        {yamlData.content.map((data, index) => {
          return (
            <Col lg={3} md={3} sm={12} xs={12} key={`content_item_${index}`}>
              <article>
                <figure>
                  <Image
                    src={data.item.picture}
                    fluid
                    rounded
                    alt={data.item.alt}
                  />
                </figure>
                <div>
                  <h4 className={subTitle}>{data.item.name}</h4>
                  <p className={para}>{data.item.description}</p>
                </div>
              </article>
            </Col>
          )
        })}
      </Row>
    </Container>
  </section>
)

export default teams
