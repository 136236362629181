import React from "react"
import { box } from "./styles/history.module.css"
import { Col, Container, Row } from "react-bootstrap"
import yamlData from "../../content/societe/history.yaml"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const History = () => {
  const image = useStaticQuery(graphql`
    query {
      history: file(relativePath: { eq: "epaviste_auto_2_dulin.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Container className={`${box} animated fadeInUp delay-0.2s`}>
      <Row>
        <Col col={6} md={6} sm={6}>
          <article>
            <h2>{yamlData.title}</h2>
            <Img
              fluid={image.history.childImageSharp.fluid}
              alt="epaviste ile de france Dulin photo"
            />
            <h3>{yamlData.subTitle}</h3>
            <p>{yamlData.paragraphe}</p>
          </article>
        </Col>
        <Col col={6} md={6} sm={6}>
          <article>
            <h2>{yamlData.secondTitle}</h2>
            {yamlData.content.map((data, index) => {
              return (
                <div key={`content_item_${index}`}>
                  <time dateTime={data.item.date}>{data.item.stringDate}</time>
                  <h3>{data.item.subTitle}</h3>
                  <p>{data.item.paragraphe}</p>
                </div>
              )
            })}
          </article>
        </Col>
      </Row>
    </Container>
  )
}

export default History
