import React from "react"
import Layout from "../components/common/layout"
import Hr from "../components/common/hr"
import History from "../components/societe/history"
import Teams from "../components/societe/teams"
import Advantages from "../components/societe/advantages"
import Seo from "../components/common/seo"

const NotreSociete = () => (
  <Layout>
    <Seo
      title="Renseignements société Dulin Épaviste"
      description="Description de l'équipe, son histoire et les avantages de la société Enlèvement d'épave Dulin en ile de france"
    />
    <Hr />
    <History />
    <Teams />
    <Advantages />
  </Layout>
)

export default NotreSociete
